import React, { useEffect } from 'react';
import { legacy_createStore as createStore } from 'redux';
import { Provider, useSelector } from 'react-redux';
import { getCMSAppVariantId } from '@state/cms';
import { INITIAL_GLOBAL_APP_STATE, rootReducer } from '@state/store';
import { WithT, withIsServer } from '@components/hoc';
import { Error404 } from '@components/common/hero/error/Error404';
import { ThemeProvider, Global } from '@emotion/react';
import { MuiTheme } from '@theme/mercuryHolidaysTheme';
import { CssBaseline } from '@material-ui/core';
import { globalStyles } from '@theme/base';
import { GtmBootstrap } from '@model/bootstrap/gtm-bootstrap';
import { ErrorPageProps } from '@model/common/error-codes';
import locales from '../public/assets/locales/locales.json';

const ErrorWrapper = ({ statusCode, code }: ErrorPageProps) => {
  const store = createStore(rootReducer, INITIAL_GLOBAL_APP_STATE);
  return (
    <Provider store={store}>
      <Error statusCode={statusCode} code={code} />
    </Provider>
  );
};

export const Error = ({ code, isServer }: ErrorPageProps) => {
  const appVariantId = useSelector(getCMSAppVariantId);
  const localisations = { ...locales.labels, ...locales[appVariantId] };

  useEffect(() => {
    if (!isServer) {
      new GtmBootstrap().initialize();
    }
  }, []);
  return (
    <ThemeProvider theme={MuiTheme}>
      <WithT localeLabels={localisations}>
        <Global styles={globalStyles(MuiTheme)} />
        <CssBaseline />
        <Error404 code={code} />
      </WithT>
    </ThemeProvider>
  );
};

export default withIsServer(ErrorWrapper);
